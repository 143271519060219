
    import {defineComponent, ref, reactive, toRaw, UnwrapRef, onMounted, onUpdated, getCurrentInstance} from 'vue';
    import {message} from "ant-design-vue";
    import {
        InboxOutlined,
    } from '@ant-design/icons-vue';
    import {submitOperator, getOperatorDetail} from '@/network/seller';
    import baseUrl from "@/util/api"
    import {useStore} from "vuex";
    import {useRouter} from "vue-router";

    interface FormState {
        creator: string | null;
        product: string;
        sku: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        link1: string;
        link2: string;
        link3: string;
        link4: string;
        link5: string;
        file: any;
        type: number;
    }

    interface FormState1 {
        creator: string | null;
        site: string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        file: any;
        type: number;
    }

    interface FileItem {
        name?: string;
        status?: string;
        response?: any;
        url?: string;
    }

    interface FileInfo {
        file: FileItem;
        fileList: FileItem[];
    }

    interface Value {
        value?: string;
        label?: string;
    }

    export default defineComponent({
        name: "Sadd1",
        components: {
            InboxOutlined,
        },
        setup() {
            const router = useRouter();
            let dis = router.currentRoute.value.query.dis ? router.currentRoute.value.query.dis :false;
            const internalInstance: any = getCurrentInstance();
            const submitting = ref<Boolean>(false);
            const submitting1 = ref<Boolean>(false);
            const store = useStore();
            const formRef = ref();
            const formRef1 = ref();
            const activeKey = ref<string>('1');
            const formState: UnwrapRef<FormState> = reactive({
                creator: window.localStorage.getItem('uid'),
                product: '',
                sku: '',
                need1: '',
                need2: '',
                need3: '',
                need4: '',
                need5: '',
                link1: '',
                link2: '',
                link3: '',
                link4: '',
                link5: '',
                file: [],
                type: 1,
            });
            const formState1: UnwrapRef<FormState1> = reactive({
                creator: window.localStorage.getItem('uid'),
                site: '',
                need1: '',
                need2: '',
                need3: '',
                need4: '',
                need5: '',
                file: [],
                type: 2,
            });
            const rules = {
                sku: [
                    {required: true, message: '请输入sku', trigger: 'blur'}
                ],
                //need1: [{required: true, message: '请输入任务要求', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                //sitetype: [{type: 'string', required: true, message: '请选择建站类型', trigger: 'blur'}],
            };
            const rules1 = {
                site: [
                    {required: true, message: '请输入建站名', trigger: 'blur'}
                ],
                //link1: [{required: true, message: '请输入产品链接', trigger: 'blur'}],
                //need1: [{required: true, message: '请输入任务要求', trigger: 'blur'}],
                //file: [{type: 'array', required: true, message: '请上传产品截图', trigger: 'blur'}],
                //department: [{type: 'string', required: true, message: '请选择工单处理部门', trigger: 'blur'}],
            };
            const fileList = ref<FileItem[]>([]);
            const fileList1 = ref<FileItem[]>([]);
            let id = router.currentRoute.value.query.id ? router.currentRoute.value.query.id : '';
            const resetForm = () => {
                formRef.value.resetFields();
            };
            const resetForm1 = () => {
                formRef1.value.resetFields();
            };
            const handleImgChange = (info: FileInfo) => {
                console.log(info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState.file.indexOf(file.response.uploadImg);
                //console.log(index);
                //console.log(formState.file);
                formState.file.splice(index, 1);

            }
            const handleImgChange1 = (info: FileInfo) => {
                console.log(info);
                const status = info.file.status;
                //console.log('22222' + status);
                if (status !== 'uploading') {
                    //console.log(toRaw(info.file.response));
                }

                if (status === 'done') {
                    //console.log(222);
                    //formRef.value.clearValidate('file');
                    message.success(`${info.file.name} 图片上传成功.`);
                    var response = toRaw(info.file.response);
                    formState1.file.unshift(response.uploadImg);
                    //console.log(formState.file);
                } else if (status === 'error') {
                    //console.log(3333);
                    message.error(`${info.file.name} 图片上传失败.`);
                }
            };
            const handleFileRemove1 = (file) => {
                //console.log(file.response.uploadImg)
                //formState.file.value = file
                //message.warn('您点击了删除')
                console.log(file);
                //formState.file.shift();
                var index = formState1.file.indexOf(file.response.uploadImg);
                //console.log(index);
                formState1.file.splice(index, 1);

            }
            const onSubmit = () => {
                formRef.value
                    .validate().then(() => {
                    submitting.value = true;
                    submitOperator({...formState,id:id}).then((response) => {
                        if (response.status == 'done') {
                            internalInstance?.proxy.$socket.sendObj({
                                type: 'text',
                                msg: "你有新的任务！",
                                mode:3,
                                creator:window.localStorage.getItem('uname'),
                            });
                            message.success(`${response.msg} `);
                            resetForm();
                            formState.file = [];
                            fileList.value = [];
                        } else {
                            message.error(`${response.msg} `);
                        }
                        submitting.value = false;
                    })
                })
                //console.log('submit!', toRaw(formState));
            };
            const onSubmit1 = () => {
                formRef1.value
                    .validate().then(() => {
                    submitting1.value = true;
                    submitOperator({...formState1,id:id}).then((response) => {
                        if (response.status == 'done') {
                            internalInstance?.proxy.$socket.sendObj({
                                type: 'text',
                                msg: "你有新的任务！",
                                mode:3,
                                creator:window.localStorage.getItem('uname'),
                            });
                            message.success(`${response.msg} `);
                            resetForm1();
                            fileList1.value = [];
                            formState1.file = [];
                        } else {
                            message.error(`${response.msg} `);
                        }
                        submitting1.value = false;
                    })
                })
                //console.log('submit!', toRaw(formState1));
            };
            const get = () => {
                getOperatorDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        //console.log(response);
                        if (response.data.type == 1) {
                            activeKey.value = '1';
                            formState.product = response.data.product;
                            formState.sku = response.data.sku;
                            formState.need1 = response.data.need1;
                            formState.need2 = response.data.need2;
                            formState.need3 = response.data.need3;
                            formState.need4 = response.data.need4;
                            formState.need5 = response.data.need5;
                            formState.link1 = response.data.link1;
                            formState.link2 = response.data.link2;
                            formState.link3 = response.data.link3;
                            formState.link4 = response.data.link4;
                            formState.link5 = response.data.link5;
                            if (response.data.file) {
                                formState.file = response.data.file.split(',');
                                fileList.value = [];
                                var nowfile = response.data.file.split(',');
                                for (let i = 0; i < nowfile.length; i++) {
                                    //console.log(fileList2.value[i]);
                                    const temp = {
                                        name:nowfile[i].substring(nowfile[i].lastIndexOf("/")+1),
                                        status:"done",
                                        url:baseUrl.apiUrl + nowfile[i],
                                        response:{uploadImg:nowfile[i]}
                                    }
                                    fileList.value.push(temp);
                                }
                            }
                        } else if (response.data.type == 2) {
                            activeKey.value = '2';
                            formState1.site = response.data.site;
                            formState1.need1 = response.data.need1;
                            formState1.need2 = response.data.need2;
                            formState1.need3 = response.data.need3;
                            formState1.need4 = response.data.need4;
                            formState1.need5 = response.data.need5;
                            if (response.data.file) {
                                formState1.file = response.data.file.split(',');
                                fileList1.value = [];
                                var nowfile = response.data.file.split(',');
                                for (let i = 0; i < nowfile.length; i++) {
                                    //console.log(fileList2.value[i]);
                                    const temp = {
                                        name:nowfile[i].substring(nowfile[i].lastIndexOf("/")+1),
                                        status:"done",
                                        url:baseUrl.apiUrl + nowfile[i],
                                        response:{uploadImg:nowfile[i]}
                                    }
                                    fileList1.value.push(temp);
                                }
                            }
                        }
                    } else {
                        resetForm();
                        fileList.value = [];
                        resetForm1();
                        fileList1.value = [];
                    }
                })
            }
            onMounted(() => {
                if (id) {
                    get();
                }
            })
            return {
                activeKey,
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
                dis,
                formRef,
                formRef1,
                formState,
                formState1,
                onSubmit,
                onSubmit1,
                fileList,
                fileList1,
                handleImgChange,
                handleFileRemove,
                handleImgChange1,
                handleFileRemove1,
                rules,
                rules1,
                resetForm,
                resetForm1,
                submitting,
                submitting1,
                baseUrl: baseUrl.apiUrl,
                header: {
                    authorization: store.state.token,
                },
            };
        },
    });
